<template>
  <div class="advertiser-list-table">
    <div v-if="loader && localAdvertisers.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else-if="user">
      <!-- FILTERS -->
      <div class="d-flex align-items-center mb-4">
        <div class="me-3">
          <form class="default-form" @submit.prevent="reset">
            <span class="fw-bold form-label me-3">Nome:</span>
            <input
              type="text"
              class="form-control form-control-sm"
              placeholder=""
              v-model="filters.name"
            />
          </form>
        </div>
        <div class="me-3 default-form">
          <span class="fw-bold form-label me-3">Categoria:</span><br/>
          <template v-if="categoriesLoader">
            <Loader
              :size="'small'"
              :align="'start'"
            />
          </template>
          <div class="custom-select custom-select-sm" v-else>
            <select
              v-model="filters.category_id"
              class="form-control form-control-sm"
            >
              <option value="">Todas</option>
              <option :value="category.id" v-for="(category, index) in categories" :key="index">
                {{ category.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="me-3">
          <span class="fw-bold form-label me-3">Status:</span><br/>
          <div class="default-form">
            <div class="custom-select custom-select-sm">
              <select
                v-model="filters.status"
                class="form-control form-control-sm"
              >
                <option value="">Todos</option>
                <option value="ACTIVE">Ativo</option>
                <option value="INACTIVE">Desativado</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <Button
            type="button"
            class="btn-primary btn-xs mt-3"
            @click="reset"
          >
            Pesquisar
          </Button>
        </div>
      </div> <!-- END FILTERS -->
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr class="text-nowrap">
                  <th>Nome</th>
                  <th>Website</th>
                  <th>Categoria</th>
                  <th>Saldo disponível</th>
                  <th>Saldo alocado</th>
                  <th>Saldo pendente</th>
                  <th>Status</th>
                  <th width="100">
                    Detalhes
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(advertiser, index) in localAdvertisers" :key="index" class="text-nowrap">
                  <td>
                    <router-link :to="{ name: 'advertisers.show', params: { id: advertiser.id } }" class="link-color3">
                      {{ advertiser.name }}
                    </router-link>
                  </td>
                  <td>{{ advertiser.website }}</td>
                  <td>{{ advertiser.category.name }}</td>
                  <td>{{ advertiser.available_amount / 100 | formatMoney }}</td>
                  <td>{{ advertiser.allocated_amount / 100 | formatMoney }}</td>
                  <td>{{ advertiser.pending_amount / 100 | formatMoney }}</td>
                  <td>
                    {{ advertiser.status | formatStatus }}
                    <template v-if="advertiser.status === 'ERROR'">
                      <RetryAdvertiserCreationOnPlatformButton
                        :advertiserId="advertiser.id"
                        @retry-successful="reset"
                        @retry-error="handleRetryError"
                      />
                    </template>
                  </td>
                  <td class="text-center">
                    <router-link :to="{ name: 'advertisers.show', params: { id: advertiser.id } }" class="link-color3"  v-b-tooltip.hover title="Ver mais">
                      <i class="fa fa-eye"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="text-center mt-4">
        <Button
          class="btn-primary btn-sm"
          :loading="loader"
          @click="loadMore"
          v-if="isLoadMoreVisible"
        >
          Carregar mais
        </Button>
      </div>
    </template>
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import CategoriesService from '@/modules/categories/services/categories-service'
import { mapState } from 'vuex'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import RetryAdvertiserCreationOnPlatformButton from '@/components/advertisers/RetryAdvertiserCreationOnPlatformButton'

export default {
  name: 'AdvertiserListTable',
  props: ['advertisers', 'reload'],
  components: {
    Button,
    Loader,
    RetryAdvertiserCreationOnPlatformButton
  },
  data () {
    return {
      categories: [],
      categoriesLoader: false,
      filters: {
        category_id: '',
        name: '',
        status: ''
      },
      localAdvertisers: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.advertisers) {
      this.localAdvertisers = this.advertisers
      this.isLoadMoreVisible = false
    } else {
      if (this.user) {
        this.getAdvertisers()
        this.getCategories()
      }
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    },
    user (newUser) {
      this.getAdvertisers()
      this.getCategories()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      if (!this.user) return false

      this.loader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          category_id: this.filters.category_id,
          companyId: this.user.company.id,
          name: this.filters.name,
          status: this.filters.status,
          page: this.page
        })
        this.localAdvertisers = [...this.localAdvertisers, ...advertisers.data]
        this.isLoadMoreVisible = advertisers.meta.current_page !== advertisers.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Get categories
     */
    async getCategories () {
      this.categoriesLoader = true
      try {
        const categories = await CategoriesService.getCategories({
          company_id: this.user.company.id,
          type: 'ADVERTISER'
        })
        this.categories = categories.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.categoriesLoader = false
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getAdvertisers()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localAdvertisers = []
      this.getAdvertisers()
    }
  }
}
</script>
